import { navigate } from 'gatsby';
import React, { Fragment, useEffect } from 'react';

import coronaLogo from '../../../static/icons/agegate.png';
import Seo from '../../components/Seo';
import s from './index.module.scss';

const AgeGate = () => {
  useEffect(() => {
    const age = localStorage.getItem('age');
    if (age && age === '+18') {
      return navigate('/home');
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('age', '+18');
    navigate('/home');
  };

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Bienvenido a coronasunsetchile.com"
        title="Inicio"
      />

      <div className={s.container}>
        <div className={s.top}>
          <img className={s.logo} alt="coronaLogo" src={coronaLogo} />
        </div>

        <div className={s.bot}>
          <h2 className={s.text}>BIENVENIDO</h2>
          <h1 className={s.text}>¿Eres mayor de edad?</h1>

          <div>
            <button className={s.button} onClick={onSubmit}>
              si
            </button>
            <button
              className={s.button}
              onClick={() => navigate('https://google.com')}
            >
              no
            </button>
          </div>

          <p className={s.parrafo}>
            Beber con moderación. Prohibida su venta a menores de 18 años. Favor
            no compartir contenido con menores de edad. Cerveza Corona 2020.
          </p>
          <div className={s.terms}>
            TÉRMINOS DE USO &nbsp;&nbsp; | &nbsp;&nbsp; POLÍTICAS DE PRIVACIDAD
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AgeGate;
